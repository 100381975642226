/* Sidebar container */
.sidebar {
  width: 60px; /* Initial width for icons only */
  background-color: #d7e6e0;
  height: 100vh;
  position: fixed;
  overflow-x: hidden;
  top: 72px;
  padding-top: 8px;
  transition: width .15s;
  @media (max-width: 1023px) {
    width: 280px;
  }
  @media (max-width: 767px) {
    top: 60px;
  }
  &:hover {
    @media (min-width: 1024px) {
      width: 280px;
      & .submenu,
      & .arrowIcon {
        display: block;
      }
    }
  }
  & :global(.NamedLink_active) {
    background-color: #00bf99 !important;
    color: #fff;
    & svg {
      path {
        fill: #fff;
      }
    }
  }
}

/* List styling */
.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* List items */
.sidebar li,
.sidebar a {
  display: flex;
  align-items: center;
  padding: 15px 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-transform: capitalize;

  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0em;
  text-align: center;
  color: #000;
  white-space: pre;
  &:hover,
  &:focus {
    text-decoration: unset;
  }
  &:hover {
    background-color: #c8d7d1;
  }
}

/* Icon styling */
.sidebar .icon {
  font-size: 20px;
  color: #6c757d;
  margin-right: 12px; /* Space for text when expanded */
  transition: margin 0.3s ease; /* Smooth margin shift */
  min-width: 34px;
}

/* Text styling */
.sidebar .text {
  font-size: 16px;
  color: #495057;
  white-space: nowrap;
  transition: opacity 0.3s ease; /* Smooth text reveal */
}
.logoutLink{
}
.submenu {
  @media (min-width: 1024px) {
    display: none;
  }
  & .blankSpace {
    min-width: 34px;
    margin-right: 12px;
    display: block;
  }
  & li,
  & a {
    padding: 9px 14px;
  }
}
.accountSettingLink {
  position: relative;
  & .arrowIcon {
    @media (min-width: 1024px) {
      display: none;
    }
    position: absolute;
    right: 24px;
  }
  & .arrowIconUp {
    rotate: 180deg;
    transition: rotate 0.2s;
  }
}
/* .accountSettingLinkActive{
  background-color: gray !important;
  color: #fff !important;
  & svg{
      path{
          fill: #fff;
      }
  }
 } */
.iconContainer {
  position: relative;
  & .notificationDot {
   /* Dimensions */
   width: 8px;
   height: 8px;
   border-radius: 50%;
 
   /* Position */
  position: absolute;
  top: 0;
  right: 15px;
 
   /* Style: red dot with white border */
   background-color: var(--colorFail);
 
   animation-name: notificationPop;
   animation-duration: 0.1s;
   animation-delay: 0.1s;
   animation-fill-mode: forwards;
   animation-timing-function: ease-in;
 }
}
